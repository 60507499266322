import React from 'react'
import { FaLinkedin } from "react-icons/fa";
import { FaGithubSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";



const HeaderSocials = () => {
    return (
        <div className='header__socials'>
            <a href='https://www.linkedin.com/in/joshrboepple/' rel="noopener"><FaLinkedin /></a>
            <a href='https://github.com/jboe26' rel="noopener"><FaGithubSquare /></a>
            <a href='https://www.facebook.com/jbope23' rel="noopener"><FaFacebookSquare /></a>
        </div>
    )
}

export default HeaderSocials
