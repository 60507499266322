import React from 'react';
import './anchortech.css';
import { FaCheck } from "react-icons/fa";

const AnchorTech = () => {
  return (
    <section id='anchortech'>
      <h5>Welcome to</h5>
      <h2>AnchorTech Innovations</h2>
      <p>Integrating tech support and web development with Christian values to empower individuals and businesses with faith-driven excellence.</p>
      
      <div className="container services__container">
        <article className='service'>
          <div className='service__head'>
            <h3>Remote Tech Support</h3>
          </div>
          <ul className='service__list'>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Troubleshooting</p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Software Installation</p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p>System Optimization</p>
            </li>
          </ul>
        </article>
        
        <article className='service'>
          <div className='service__head'>
            <h3>Web Development</h3>
          </div>
          <ul className='service__list'>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Custom Websites</p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p>E-commerce Solutions</p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Website Maintenance</p>
            </li>
          </ul>
        </article>
        
        <article className='service'>
          <div className='service__head'>
            <h3>Cybersecurity Solutions</h3>
          </div>
          <ul className='service__list'>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Data Protection</p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Network Security</p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Threat Prevention</p>
            </li>
          </ul>
        </article>
        
        <article className='service'>
          <div className='service__head'>
            <h3>IT Consultancy</h3>
          </div>
          <ul className='service__list'>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Infrastructure Strategy</p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p>System Integration</p>
            </li>
            <li>
              <FaCheck className='service__list-icon' />
              <p>Technology Roadmap</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default AnchorTech;
